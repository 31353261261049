import { NgModule } from '@angular/core';
import { AngularD3CloudComponent } from './angular-d3-cloud.component';



@NgModule({
  declarations: [AngularD3CloudComponent],
  imports: [
  ],
  exports: [AngularD3CloudComponent]
})
export class AngularD3CloudModule { }
